import "./Skills.css";
import Modal from "./UI/Modal";

function Skills(props) {
  const mySkills = [
    { skill: "C#", years: 6 },
    { skill: "SQL", years: 6 },
    { skill: "REST API", years: 2 },
    { skill: "JavaScript", years: 5 },
    { skill: "Agile", years: 3 },
    { skill: "GIT", years: 4 },
    { skill: "JIRA", years: 4 },
    { skill: ".NET", years: 6 },
    { skill: "VBA", years: 8 },
    { skill: "Scrum", years: 4 },
    { skill: "MVC", years: 3 },
    { skill: "Decisions", years: 2 },
    { skill: "Jenkins", years: 2 },
    { skill: "Confluence", years: 2 },
    { skill: "IIS", years: 2 },
    { skill: "Server System Administration", years: 2 },
    { skill: "React", years: 1 },
    { skill: "Leadership", years: 10 },
    { skill: "Mentorship", years: 10 },
    { skill: "UI/UX", years: 5 },
    { skill: "Unit Testing", years: 3 },
    { skill: "SVN", years: 2 },
    { skill: "XML", years: 6 },
    { skill: "XAML", years: 6 },
    { skill: "WPF", years: 5 },
    { skill: "Winforms", years: 6 },
    { skill: "ASP.Net", years: 3 },
    { skill: "SDLC", years: 6 },
  ];

  return (
    <Modal onCloseClick={props.closeSkills} width={30} height={70}>
      <h2 className="skillsHeader">Skills</h2>
      <div className="overallDIV">
        {mySkills
          .sort((a, b) => (a.skill > b.skill ? 1 : -1))
          .map(({ skill, years }) => (
            <li key={skill}>
              {skill} - {years} years
            </li>
          ))}
      </div>
    </Modal>
  );
}

export default Skills;
