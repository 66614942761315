import Modal from "./UI/Modal";
import "./Education.css";

function Education(props) {
  return (
    <Modal onCloseClick={props.closeEducation} width={40} height={50}>
      <h2 className="educationHeader">Education</h2>
      <div className="overallEducation">
        <div>
          <div className="educationLogo">
            <img src="USU_logo.png" width="100px" alt="USU logo" />
          </div>
          <div className="info padHelper">
            <h3 className="padHelper">Utah State University</h3>
            <p className="degree padHelper">No Degree Earned</p>
            <h4 className="padHelper">2009 - 2012</h4>
            <p className="degree padHelper">
              Area of study: Graphic Design, Education Administration,
              Management Information Systems
            </p>
          </div>
        </div>
        <div className="topSpacer">
          <div className="educationLogoCC">
            <img
              src="Columbia_College_logo.png"
              width="100px"
              alt="Columbia College Logo"
            />
          </div>
          <div className="info padHelper">
            <h3 className="padHelper">Columbia College of Missouri</h3>
            <p className="degree padHelper">
              B.S. in Computer Information Systems
            </p>
            <h4 className="padHelper">2017 - 2019</h4>
            <p className="degree padHelper">
              Area of study: Computer Information Systems
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default Education;
