import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useGLTF, Html } from "@react-three/drei";
import { Select } from "@react-three/postprocessing";
import { useFrame, useThree } from "@react-three/fiber";
import { gsap } from "gsap";
import "./LowPolyIsland.css";

// const hoverHelper = (props) => {
//   //console.log(props);
// };

const Model = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF("low_poly_flying_island.glb");
  const [isHover, setIsHover] = useState(false);
  const [isLighthouseClicked, setIsLighthouseClicked] = useState(false);
  const [isTopRightCloudClicked, setIsTopRightCloudClicked] = useState(false);
  const [isFrontTreeClicked, setIsFrontTreeClicked] = useState(false);
  const [isTopLeftCloudClicked, setIsTopLeftCloudClicked] = useState(false);
  const [isResetCameraClicked, setIsResetCameraClicked] = useState(false);
  const [isLighthouseHover, setIsLighthouseHover] = useState(false);
  const [isTopRightCloudHover, setIsTopRightCloudHover] = useState(false);
  const [isFrontTreeHover, setIsFrontTreeHover] = useState(false);
  const [isTopLeftCloudHover, setIsTopLeftCloudHover] = useState(false);
  const [isBottomRockHover, setIsBottomRockHover] = useState(false);
  const [isBottomRockClicked, setIsBottomRockClicked] = useState(false);

  const { camera } = useThree();

  useImperativeHandle(ref, () => ({
    cameraResetClickHandler() {
      setIsResetCameraClicked(true);
    },
  }));

  useFrame((state) => {
    if (isLighthouseClicked) {
      gsap.to(state.camera.position, {
        x: props.lightHouseCameraPosition.x,
        y: props.lightHouseCameraPosition.y,
        z: props.lightHouseCameraPosition.z,
        duration: 2,
      });
      props.onSetScale(0);
      props.showAboutMe();
      props.onSetCamera(camera);
      setIsLighthouseClicked(false);
    }
    return null;
  });

  useFrame((state) => {
    if (isTopRightCloudClicked) {
      gsap.to(state.camera.position, {
        x: props.toprightCloudCameraPosition.x,
        y: props.toprightCloudCameraPosition.y,
        z: props.toprightCloudCameraPosition.z,
        duration: 2,
      });
      props.onSetCamera(camera);
      props.onSetScale(0.57);
      props.showContactMe();
      setIsTopRightCloudClicked(false);
    }
    return null;
  });

  useFrame((state) => {
    if (isFrontTreeClicked) {
      gsap.to(state.camera.position, {
        x: props.fronttreeCameraPosition.x,
        y: props.fronttreeCameraPosition.y,
        z: props.fronttreeCameraPosition.z,
        duration: 2,
      });
      props.onSetCamera(camera);
      props.onSetScale(0.2);
      props.showEducation();
      setIsFrontTreeClicked(false);
    }
    return null;
  });

  useFrame((state) => {
    if (isTopLeftCloudClicked) {
      gsap.to(state.camera.position, {
        x: props.topleftCloudCameraPosition.x,
        y: props.topleftCloudCameraPosition.y,
        z: props.topleftCloudCameraPosition.z,
        duration: 2,
      });
      props.onSetCamera(camera);
      props.onSetScale(0.2);
      props.showWorkExperience();
      setIsTopLeftCloudClicked(false);
    }
    return null;
  });

  useFrame((state) => {
    if (isBottomRockClicked) {
      gsap.to(state.camera.position, {
        x: props.bottomrockCameraPosition.x,
        y: props.bottomrockCameraPosition.y,
        z: props.bottomrockCameraPosition.z,
        duration: 2,
      });
      props.onSetCamera(camera);
      props.onSetScale(0.2);
      props.showSkills();
      setIsBottomRockClicked(false);
    }
    return null;
  });

  useEffect(() => {
    document.body.style.cursor = isHover ? "pointer" : "grab";
  }, [isHover]);

  useEffect(() => {
    if (isResetCameraClicked) {
      gsap.to(camera.position, {
        x: props.defaultCameraPosition.x,
        y: props.defaultCameraPosition.y,
        z: props.defaultCameraPosition.z,
        duration: 2,
      });
      props.onSetCamera(camera);
      setIsResetCameraClicked(false);
    }
  }, [isResetCameraClicked, camera, props]);

  return (
    <group {...props} dispose={null}>
      <group name="Sketchfab_Scene">
        <group
          name="Sketchfab_model"
          rotation={props.ObjectRotation}
          scale={props.ObjectScale}
          //onPointerEnter={hoverHelper}
        >
          <group name="root">
            <group name="GLTF_SceneRootNode" rotation={[Math.PI / 2, 0, 0]}>
              <group name="Icosphere_2" scale={10}>
                <mesh
                  name="Object_4"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_4.geometry}
                  material={materials["Material.003"]}
                />
              </group>

              <group
                name="Plane_3"
                position={[-0.01, 1.45, -0.02]}
                rotation={[0, -0.64, 0]}
                scale={10.1}
              >
                <mesh
                  name="Object_6"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_6.geometry}
                  material={materials["Material.001"]}
                />
              </group>

              <group
                name="Cube_4"
                position={[0.38, 1.76, 2.29]}
                rotation={[0.46, -0.03, 0.37]}
                scale={0.1}
              >
                <mesh
                  name="Object_8"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_8.geometry}
                  material={materials["Material.002"]}
                />
                <mesh
                  name="Object_9"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_9.geometry}
                  material={materials["Material.004"]}
                />
                <mesh
                  name="Object_10"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_10.geometry}
                  material={materials["Material.005"]}
                />
                <mesh
                  name="Object_11"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_11.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Plane001_5"
                position={[7.07, 2.17, 0.82]}
                rotation={[0.28, -0.91, 0.35]}
                scale={0.04}
              >
                <mesh
                  name="Object_13"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_13.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_14"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_14.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <Select enabled={isLighthouseHover || props.highlightEverything}>
                <group
                  name="Cylinder001_6" //Lighthouse
                  position={[-4.59, 2.09, 1.68]}
                  rotation={[0, 0.62, 0]}
                  scale={[0.39, 0.25, 0.39]}
                  onPointerOver={() => {
                    setIsHover(true);
                    setIsLighthouseHover(true);
                  }}
                  onPointerOut={() => {
                    setIsHover(false);
                    setIsLighthouseHover(false);
                  }}
                  onClick={() => setIsLighthouseClicked(true)}
                >
                  {(isLighthouseHover || props.highlightEverything) && (
                    <Html distanceFactor={10}>
                      <p className="toolTip">About Me</p>
                    </Html>
                  )}
                  <mesh
                    name="Object_16"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_16.geometry}
                    material={materials.Material}
                  />
                  <mesh
                    name="Object_17"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_17.geometry}
                    material={materials["Material.007"]}
                  />
                  <mesh
                    name="Object_18"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_18.geometry}
                    material={materials["Material.008"]}
                  />
                  <mesh
                    name="Object_19"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_19.geometry}
                    material={materials["Material.009"]}
                  />
                </group>
              </Select>
              <group
                name="Cube001_7"
                position={[1.16, 1.41, 5.19]}
                rotation={[0.24, 0.5, 0.22]}
                scale={0.12}
              >
                <mesh
                  name="Object_21"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_21.geometry}
                  material={materials["Material.002"]}
                />
                <mesh
                  name="Object_22"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_22.geometry}
                  material={materials["Material.004"]}
                />
                <mesh
                  name="Object_23"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_23.geometry}
                  material={materials["Material.005"]}
                />
                <mesh
                  name="Object_24"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_24.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Cube002_8"
                position={[1.15, 1.3, 4.52]}
                rotation={[3.12, 1.19, 3.09]}
                scale={0.07}
              >
                <mesh
                  name="Object_26"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_26.geometry}
                  material={materials["Material.002"]}
                />
                <mesh
                  name="Object_27"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_27.geometry}
                  material={materials["Material.004"]}
                />
                <mesh
                  name="Object_28"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_28.geometry}
                  material={materials["Material.005"]}
                />
                <mesh
                  name="Object_29"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_29.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Plane002_9"
                position={[6.21, 1.93, -1.14]}
                rotation={[0.33, -0.45, 0.09]}
                scale={0.04}
              >
                <mesh
                  name="Object_31"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_31.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_32"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_32.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane003_10"
                position={[6.74, 1.98, -0.39]}
                rotation={[0, 0, 0.39]}
                scale={0.07}
              >
                <mesh
                  name="Object_34"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_34.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_35"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_35.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane004_11"
                position={[7.47, 2.27, 0.14]}
                rotation={[-0.01, -0.11, -0.04]}
                scale={0.08}
              >
                <mesh
                  name="Object_37"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_37.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_38"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_38.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane005_12"
                position={[5.98, 1.63, 0.2]}
                rotation={[-2.83, 0.36, 2.76]}
                scale={0.03}
              >
                <mesh
                  name="Object_40"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_40.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_41"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_41.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Cube003_13"
                position={[-0.02, 1.66, -4.54]}
                rotation={[1.91, 1.43, -1.63]}
                scale={0.09}
              >
                <mesh
                  name="Object_43"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_43.geometry}
                  material={materials["Material.002"]}
                />
                <mesh
                  name="Object_44"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_44.geometry}
                  material={materials["Material.004"]}
                />
                <mesh
                  name="Object_45"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_45.geometry}
                  material={materials["Material.005"]}
                />
                <mesh
                  name="Object_46"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_46.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Cube004_14"
                position={[-1.11, 1.61, -2.8]}
                rotation={[2.58, 1.38, -2.42]}
                scale={0.07}
              >
                <mesh
                  name="Object_48"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_48.geometry}
                  material={materials["Material.002"]}
                />
                <mesh
                  name="Object_49"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_49.geometry}
                  material={materials["Material.004"]}
                />
                <mesh
                  name="Object_50"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_50.geometry}
                  material={materials["Material.005"]}
                />
                <mesh
                  name="Object_51"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_51.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Cube005_15"
                position={[-0.36, 1.47, -3.12]}
                rotation={[3.12, -0.4, -2.85]}
                scale={0.12}
              >
                <mesh
                  name="Object_53"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_53.geometry}
                  material={materials["Material.002"]}
                />
                <mesh
                  name="Object_54"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_54.geometry}
                  material={materials["Material.004"]}
                />
                <mesh
                  name="Object_55"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_55.geometry}
                  material={materials["Material.005"]}
                />
                <mesh
                  name="Object_56"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_56.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Plane006_16"
                position={[-5.64, 1.85, 0.24]}
                rotation={[0.2, 0.68, -0.16]}
                scale={0.06}
              >
                <mesh
                  name="Object_58"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_58.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_59"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_59.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <Select enabled={isFrontTreeHover || props.highlightEverything}>
                <group
                  name="Plane007_17" //Front Tree
                  position={[0.43, 2.51, 7.95]}
                  rotation={[-0.04, 0.1, 0.27]}
                  scale={0.07}
                  onPointerOver={() => {
                    setIsHover(true);
                    setIsFrontTreeHover(true);
                  }}
                  onPointerOut={() => {
                    setIsHover(false);
                    setIsFrontTreeHover(false);
                  }}
                  onClick={() => setIsFrontTreeClicked(true)}
                >
                  {(isFrontTreeHover || props.highlightEverything) && (
                    <Html distanceFactor={5}>
                      <p className="toolTip">Education</p>
                    </Html>
                  )}
                  <mesh
                    name="Object_61"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_61.geometry}
                    material={materials["Material.010"]}
                  />
                  <mesh
                    name="Object_62"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_62.geometry}
                    material={materials["Material.011"]}
                  />
                </group>
              </Select>
              <group
                name="Cube006_18"
                position={[-2.3, 1.57, -3.78]}
                rotation={[2.99, 0.56, -2.92]}
                scale={0.06}
              >
                <mesh
                  name="Object_64"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_64.geometry}
                  material={materials["Material.002"]}
                />
                <mesh
                  name="Object_65"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_65.geometry}
                  material={materials["Material.004"]}
                />
                <mesh
                  name="Object_66"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_66.geometry}
                  material={materials["Material.005"]}
                />
                <mesh
                  name="Object_67"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_67.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Plane008_19"
                position={[-6.15, 2.02, 0.31]}
                rotation={[0.05, 0.26, -0.11]}
                scale={0.03}
              >
                <mesh
                  name="Object_69"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_69.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_70"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_70.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane009_20"
                position={[-5.18, 1.7, 0.69]}
                rotation={[0.09, -0.42, 0.12]}
                scale={0.04}
              >
                <mesh
                  name="Object_72"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_72.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_73"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_73.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane010_21"
                position={[-0.31, 1.96, 2.1]}
                rotation={[0.16, -0.18, -0.1]}
                scale={0.04}
              >
                <mesh
                  name="Object_75"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_75.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_76"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_76.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane011_22"
                position={[-0.73, 2.43, 1.08]}
                rotation={[0.13, -0.09, -0.07]}
                scale={0.03}
              >
                <mesh
                  name="Object_78"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_78.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_79"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_79.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Cube007_23"
                position={[0.29, 2.06, 1.12]}
                rotation={[2.57, 0.97, -2.46]}
                scale={0.1}
              >
                <mesh
                  name="Object_81"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_81.geometry}
                  material={materials["Material.002"]}
                />
                <mesh
                  name="Object_82"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_82.geometry}
                  material={materials["Material.004"]}
                />
                <mesh
                  name="Object_83"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_83.geometry}
                  material={materials["Material.005"]}
                />
                <mesh
                  name="Object_84"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_84.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <Select
                enabled={isTopLeftCloudHover || props.highlightEverything}
              >
                <group
                  name="Icosphere001_24" //Top Left Cloud
                  position={[-6.82, 6.28, -4.47]}
                  rotation={[0, -0.02, 0]}
                  scale={0.72}
                  onPointerOver={() => {
                    setIsHover(true);
                    setIsTopLeftCloudHover(true);
                  }}
                  onPointerOut={() => {
                    setIsHover(false);
                    setIsTopLeftCloudHover(false);
                  }}
                  onClick={() => setIsTopLeftCloudClicked(true)}
                >
                  {(isTopLeftCloudHover || props.highlightEverything) && (
                    <Html distanceFactor={10}>
                      <p className="toolTipWorkExperience">Work Experience</p>
                    </Html>
                  )}
                  <mesh
                    name="Object_86"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_86.geometry}
                    material={materials.material_0}
                  />
                </group>
              </Select>
              <group
                name="Icosphere002_25"
                position={[-6.31, 5.04, -3.6]}
                rotation={[-0.39, 0, -0.08]}
                scale={0.53}
              >
                <mesh
                  name="Object_88"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_88.geometry}
                  material={materials.cloud}
                />
              </group>
              <Select
                enabled={isTopRightCloudHover || props.highlightEverything}
              >
                <group
                  name="Icosphere003_26" // top right cloud
                  position={[6.6, 6.27, 0.85]}
                  rotation={[0.88, 0.08, 0.42]}
                  scale={0.88}
                  onClick={() => {
                    setIsTopRightCloudClicked(true);
                  }}
                  onPointerOver={() => {
                    setIsHover(true);
                    setIsTopRightCloudHover(true);
                  }}
                  onPointerOut={() => {
                    setIsHover(false);
                    setIsTopRightCloudHover(false);
                  }}
                >
                  {(isTopRightCloudHover || props.highlightEverything) && (
                    <Html distanceFactor={10}>
                      <p className="toolTipContact">Contact Me</p>
                    </Html>
                  )}
                  <mesh
                    name="Object_90"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_90.geometry}
                    material={materials.cloud}
                  />
                </group>
              </Select>
              <group
                name="Icosphere004_27"
                position={[3.23, 5.23, -5.02]}
                rotation={[-1.93, 0.6, -0.27]}
                scale={[0.76, 0.76, 0.89]}
              >
                <mesh
                  name="Object_92"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_92.geometry}
                  material={materials.material_0}
                />
              </group>
              <group
                name="Icosphere005_28"
                position={[7.47, 4.74, 4.55]}
                rotation={[3.08, -0.01, -3.08]}
                scale={0.6}
              >
                <mesh
                  name="Object_94"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_94.geometry}
                  material={materials.material_0}
                />
              </group>
              <group
                name="Plane012_29"
                position={[4.44, 1.6, -5.01]}
                rotation={[0.1, 1.23, 0.09]}
                scale={0.07}
              >
                <mesh
                  name="Object_96"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_96.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_97"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_97.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane013_30"
                position={[4.11, 2.75, -6.13]}
                rotation={[-2.97, 1.37, -3.01]}
                scale={0.07}
              >
                <mesh
                  name="Object_99"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_99.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_100"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_100.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane014_31"
                position={[4.53, 2.58, -5.76]}
                rotation={[-0.07, 1.29, 0.23]}
                scale={0.05}
              >
                <mesh
                  name="Object_102"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_102.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_103"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_103.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane015_32"
                position={[4.92, 2.84, -5.92]}
                rotation={[-0.24, -0.73, -0.13]}
                scale={0.03}
              >
                <mesh
                  name="Object_105"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_105.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_106"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_106.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane016_33"
                position={[-0.11, 2.26, 8.06]}
                rotation={[-0.04, 0.1, 0.27]}
                scale={0.04}
              >
                <mesh
                  name="Object_108"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_108.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_109"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_109.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Plane017_34"
                position={[0.1, 2.03, 8.68]}
                rotation={[-2.5, 0.7, 2.63]}
                scale={0.06}
              >
                <mesh
                  name="Object_111"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_111.geometry}
                  material={materials["Material.010"]}
                />
                <mesh
                  name="Object_112"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_112.geometry}
                  material={materials["Material.011"]}
                />
              </group>
              <group
                name="Cube012_35"
                position={[6.46, 1.89, 0.89]}
                rotation={[2.95, 0.62, -3.1]}
                scale={0.07}
              >
                <mesh
                  name="Object_114"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_114.geometry}
                  material={materials["Material.002"]}
                />
                <mesh
                  name="Object_115"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_115.geometry}
                  material={materials["Material.004"]}
                />
                <mesh
                  name="Object_116"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_116.geometry}
                  material={materials["Material.005"]}
                />
                <mesh
                  name="Object_117"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_117.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Icosphere006_36"
                position={[5.79, 5.1, 0.83]}
                rotation={[0.8, -0.43, 0.69]}
                scale={0.41}
              >
                <mesh
                  name="Object_119"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_119.geometry}
                  material={materials.cloud}
                />
              </group>
              <group
                name="Icosphere007_37"
                position={[6.73, 5.22, 1.49]}
                rotation={[0.04, 0.13, 0.08]}
                scale={0.24}
              >
                <mesh
                  name="Object_121"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_121.geometry}
                  material={materials.cloud}
                />
              </group>

              <group
                name="Icosphere008_38"
                position={[4, -3.07, 6.58]}
                rotation={[2.67, -0.43, 1.3]}
                scale={0.33}
              >
                <mesh
                  name="Object_123"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_123.geometry}
                  material={materials["Material.004"]}
                />
              </group>

              <group
                name="Icosphere009_39"
                position={[3.36, -2.7, 7.04]}
                rotation={[1.3, -0.65, -0.77]}
                scale={0.29}
              >
                <mesh
                  name="Object_125"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_125.geometry}
                  material={materials["Material.004"]}
                />
              </group>
              <Select enabled={isBottomRockHover || props.highlightEverything}>
                <group
                  name="Icosphere010_40" //Bottom Rock
                  position={[3.4, -3.44, 6.62]}
                  rotation={[1.13, 0.39, -2.12]}
                  scale={0.41}
                  onPointerOver={() => {
                    setIsHover(true);
                    setIsBottomRockHover(true);
                  }}
                  onPointerOut={() => {
                    setIsHover(false);
                    setIsBottomRockHover(false);
                  }}
                  onClick={() => setIsBottomRockClicked(true)}
                >
                  {(isBottomRockHover || props.highlightEverything) && (
                    <Html distanceFactor={10}>
                      <p className="toolTip">Skills</p>
                    </Html>
                  )}
                  <mesh
                    name="Object_127"
                    castShadow
                    receiveShadow
                    geometry={nodes.Object_127.geometry}
                    material={materials["Material.004"]}
                  />
                </group>
              </Select>
              <group
                name="Icosphere011_41"
                position={[2.66, 2.07, -4.71]}
                rotation={[-0.37, 0.43, 0.54]}
                scale={0.22}
              >
                <mesh
                  name="Object_129"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_129.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Icosphere012_42"
                position={[2.35, 2.07, -4.7]}
                rotation={[2.03, -0.09, 2.5]}
                scale={0.09}
              >
                <mesh
                  name="Object_131"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_131.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Icosphere013_43"
                position={[2.44, 1.99, -4.61]}
                rotation={[-0.02, 0.8, 0.7]}
                scale={0.05}
              >
                <mesh
                  name="Object_133"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_133.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Icosphere014_44"
                position={[0.17, 1.35, 3.06]}
                rotation={[-1.77, 0.43, 1.26]}
                scale={0.44}
              >
                <mesh
                  name="Object_135"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_135.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Icosphere015_45"
                position={[-0.28, 1.29, 3.2]}
                rotation={[2.03, -0.09, 2.5]}
                scale={0.18}
              >
                <mesh
                  name="Object_137"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_137.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Icosphere016_46"
                position={[-0.05, 1.1, 3.37]}
                rotation={[3.13, 0.18, 2.93]}
                scale={0.09}
              >
                <mesh
                  name="Object_139"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_139.geometry}
                  material={materials["Material.006"]}
                />
              </group>
              <group
                name="Icosphere017_49"
                position={[4.24, -2.38, 6.7]}
                rotation={[2.67, -0.43, 1.3]}
                scale={0.29}
              >
                <mesh
                  name="Object_141"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_141.geometry}
                  material={materials["Material.004"]}
                />
              </group>
              <group
                name="Icosphere018_50"
                position={[3.89, -2.68, 6.94]}
                rotation={[-3.1, 0.52, -1.71]}
                scale={0.19}
              >
                <mesh
                  name="Object_143"
                  castShadow
                  receiveShadow
                  geometry={nodes.Object_143.geometry}
                  material={materials["Material.004"]}
                />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  );
});

useGLTF.preload("low_poly_flying_island.glb");
export default Model;
