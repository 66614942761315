import "./Toggle.css";

function Toggle(props) {
  return (
    <div className="container" style={props.style}>
      <div className="outsideLabel">{props.label} </div>
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          name={props.label}
          id={props.label}
          onClick={props.onClick}
          checked={props.isToggleChecked}
          onChange={props.onChange}
        />
        <label className="label" htmlFor={props.label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
}

export default Toggle;
