import React from "react";
import css from "./Modal.module.css";

function Modal(props) {
  return (
    <div style={{ cursor: "default" }}>
      <div className={css.backdrop}></div>
      <div
        className={css.modal}
        style={{ width: `${props.width}vw`, height: `${props.height}vh` }}
      >
        <header>
          <button className={css.closeButton} onClick={props.onCloseClick}>
            X
          </button>
        </header>
        <div>{props.children}</div>
      </div>
    </div>
  );
}

export default Modal;
