import React, { Suspense } from "react";
import Modal from "./UI/Modal";
import css from "./AboutMe.module.css";
import { Canvas } from "@react-three/fiber";
import Me from "../Model3D/Me";
import { Environment } from "@react-three/drei";

function AboutMe(props) {
  return (
    <Modal onCloseClick={props.closeAboutMe} width={60} height={65}>
      <div className="overallAboutMe">
        <div className={css.canvas}>
          <Canvas>
            <Suspense fallback={null}>
              <Me />
              <Environment preset="sunset" />
            </Suspense>
          </Canvas>
        </div>
        <div>
          <p className={css.paragraph}>
            Hello! My name is Brady and I'm a Utah native, born and raised in
            the Salt Lake City area. I currently reside in Cache Valley with my
            lovely wife and two amazing kids, who are the real reason my hair is
            going gray. In my free time, you can find me tinkering with
            electronics, 3D printing, taking photos, messing around in
            Photoshop, chugging Mountain Dew, and building things. I'm also a
            bit of a computer nerd, so if you see me shaking my head at my
            computer, don't be alarmed, I'm just trying to figure out the bug I
            caused. When I'm not busy with work, I love spending time with my
            family and being a member of The Church of Jesus Christ of
            Latter-Day Saints. I'm also always looking to further my education
            and learn new things. I currently work as an Engineering Manager at
            Conservice, where I can tell you more about utilities than you'd
            ever want to know. But don't worry, I'll spare you the details,
            unless you ask, then I can't stop. Thanks for stopping by my site
            and enjoy!
          </p>
        </div>
      </div>
    </Modal>
  );
}

export default AboutMe;
