import Modal from "./UI/Modal";
import "./WorkExperience.css";

function WorkExperience(props) {
  return (
    <Modal onCloseClick={props.closeWorkExperience} width={40} height={65}>
      <h2 className="workHistoryHeader">Work Experience</h2>
      <div className="mainSection">
        <div className="rowWE">
          <div className="colWE">
            <div>
              <img
                src="Conservice_logo.png"
                width="200px"
                className="companyLogo"
                alt="Conservice Logo"
              />
              <h3 className="workExperienceh3">2011 - Present</h3>
            </div>
            <div>
              <h4 className="workExperienceh4">Sr. Engineering Manager</h4>
              <p className="workExperienceDates">November 2020 - Present</p>
            </div>
            <div>
              <h4 className="workExperienceh4">
                Engineering Manager Assistant
              </h4>
              <p className="workExperienceDates">
                November 2019 - November 2020
              </p>
            </div>
            <div>
              <h4 className="workExperienceh4">Software Engineer II</h4>
              <p className="workExperienceDates">April 2018 - November 2019</p>
            </div>
            <div>
              <h4 className="workExperienceh4">Software Engineer I</h4>
              <p className="workExperienceDates">April 2017 - April 2018</p>
            </div>
            <div>
              <h4 className="workExperienceh4">Product Owner</h4>
              <p className="workExperienceDates">January 2015 - April 2017</p>
            </div>
            <div>
              <h4 className="workExperienceh4">New Hire Coach</h4>
              <p className="workExperienceDates">August 2013 - January 2015</p>
            </div>
            <div>
              <h4 className="workExperienceh4">Billing Manager Team Lead</h4>
              <p className="workExperienceDates">November 2012 - August 2013</p>
            </div>
            <div>
              <h4 className="workExperienceh4">Billing Manager</h4>
              <p className="workExperienceDates">
                October 2011 - November 2012
              </p>
            </div>
          </div>
          <div className="colWE">
            <div>
              <img
                src="tutor_logo.png"
                width="195px"
                className="companyLogo"
                alt="Tutor.com Logo"
              />
              <h3 className="workExperienceh3">2021 - Present</h3>
            </div>
            <div>
              <h4 className="workExperienceh4">
                Tutor - C#, Photoshop, Excel, Windows
              </h4>
              <p className="workExperienceDates">September 2011 - Present</p>
            </div>
          </div>
        </div>
      </div>
      <p className="bottomTextWE">
        *Experience greater than 10 years is not shown.
      </p>
    </Modal>
  );
}

export default WorkExperience;
