import "./App.css";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import {
  Selection,
  EffectComposer,
  Outline,
} from "@react-three/postprocessing";
import { Suspense, useRef, useState } from "react";
import LowPolyIsland from "./Model3D/LowPolyIsland";
import Header from "./components/Header";
import AboutMe from "./components/AboutMe";
import Toggle from "./components/UI/Toggle";
import ContactMe from "./components/ContactMe";
import Education from "./components/Education";
import WorkExperience from "./components/WorkExperience";
import Skills from "./components/Skills";

function App() {
  const homeScale = 0.27;
  const homeRotation = [-1.3, 0, 0.2];
  const defaultDelayMS = 2100;
  const islandRef = useRef();
  const [isAboutMeShowing, setIsAboutMeShowing] = useState(false);
  const [selected, setToggle] = useState(false);
  const [cameraObj, setCameraObj] = useState({});
  const [sceneScale, setSceneScale] = useState(homeScale);
  const [sceneRotation, setSceneRotation] = useState(homeRotation);
  const [aboutMeDelayMSeconds, setAboutMeDelayMSeconds] =
    useState(defaultDelayMS);
  const [contactMeDelayMSeconds, setContactMeDelayMSeconds] =
    useState(defaultDelayMS);
  const [isContactMeShowing, setIsContactMeShowing] = useState(false);
  const [educationDelayMSeconds, setEducationDelayMSeconds] =
    useState(defaultDelayMS);
  const [isEducationShowing, setIsEducationShowing] = useState(false);
  const [workExperienceDelayMSeconds, setWorkExperienceDelayMSeconds] =
    useState(defaultDelayMS);
  const [isWorkExperienceShowing, setIsWorkExperienceShowing] = useState(false);
  const [skillsDelayMSeconds, setSkillsDelayMSeconds] =
    useState(defaultDelayMS);
  const [isSkillsShowing, setIsSkillsShowing] = useState(false);

  const homeCameraPosition = {
    x: 0,
    y: 3.061616997868383e-16,
    z: 5,
  };

  const lighthouseCameraPosition = {
    x: -1.5139792296733832,
    y: 0.5824467991084824,
    z: 2.5301101098938164,
  };

  const topRightCloudCameraPosition = {
    x: 6.6,
    y: 6.27,
    z: 0.85,
  };

  const frontTreeCameraPosition = {
    x: 0.4567180358498866,
    y: 0.3941640347691052,
    z: 2.253811910550055,
  };

  const topLeftCloudCameraPosition = {
    x: -2.1945274709898563,
    y: 1.7896629346595307,
    z: -0.2886229292733732,
  };

  const bottomRockCameraPosition = {
    x: 1.2847873964332905,
    y: -1.4251679670649557,
    z: 1.2734385294229607,
  };

  const showAboutMe = () => {
    setToggle(false);
    setTimeout(() => {
      setIsAboutMeShowing(true);
    }, aboutMeDelayMSeconds);
    setAboutMeDelayMSeconds(0);
    setContactMeDelayMSeconds(defaultDelayMS);
    setEducationDelayMSeconds(defaultDelayMS);
    setWorkExperienceDelayMSeconds(defaultDelayMS);
    setSkillsDelayMSeconds(defaultDelayMS);
  };

  const closeAboutMe = () => {
    setIsAboutMeShowing(false);
  };

  const showContactMe = () => {
    setToggle(false);
    setTimeout(() => {
      setIsContactMeShowing(true);
    }, contactMeDelayMSeconds);
    setContactMeDelayMSeconds(0);
    setAboutMeDelayMSeconds(defaultDelayMS);
    setEducationDelayMSeconds(defaultDelayMS);
    setWorkExperienceDelayMSeconds(defaultDelayMS);
    setSkillsDelayMSeconds(defaultDelayMS);
  };

  const closeContactMe = () => {
    setIsContactMeShowing(false);
  };

  const showEducation = () => {
    setToggle(false);
    setTimeout(() => {
      setIsEducationShowing(true);
    }, educationDelayMSeconds);
    setEducationDelayMSeconds(0);
    setAboutMeDelayMSeconds(defaultDelayMS);
    setContactMeDelayMSeconds(defaultDelayMS);
    setWorkExperienceDelayMSeconds(defaultDelayMS);
    setSkillsDelayMSeconds(defaultDelayMS);
  };

  const closeEducation = () => {
    setIsEducationShowing(false);
  };

  const showWorkExperience = () => {
    setToggle(false);
    setTimeout(() => {
      setIsWorkExperienceShowing(true);
    }, workExperienceDelayMSeconds);
    setWorkExperienceDelayMSeconds(0);
    setAboutMeDelayMSeconds(defaultDelayMS);
    setEducationDelayMSeconds(defaultDelayMS);
    setContactMeDelayMSeconds(defaultDelayMS);
    setSkillsDelayMSeconds(defaultDelayMS);
  };

  const closeWorkExperience = () => {
    setIsWorkExperienceShowing(false);
  };

  const showSkills = () => {
    setToggle(false);
    setTimeout(() => {
      setIsSkillsShowing(true);
    }, skillsDelayMSeconds);
    setWorkExperienceDelayMSeconds(defaultDelayMS);
    setAboutMeDelayMSeconds(defaultDelayMS);
    setEducationDelayMSeconds(defaultDelayMS);
    setContactMeDelayMSeconds(defaultDelayMS);
    setSkillsDelayMSeconds(0);
  };

  const closeSkills = () => {
    setIsSkillsShowing(false);
  };

  const setCameraHandler = (camData) => {
    setCameraObj(camData);
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        background: "skyblue",
        top: "0",
        left: "0",
        position: "fixed",
      }}
    >
      {isAboutMeShowing && <AboutMe closeAboutMe={closeAboutMe} />}
      {isContactMeShowing && <ContactMe closeContactMe={closeContactMe} />}
      {isEducationShowing && <Education closeEducation={closeEducation} />}
      {isWorkExperienceShowing && (
        <WorkExperience closeWorkExperience={closeWorkExperience} />
      )}
      {isSkillsShowing && <Skills closeSkills={closeSkills} />}
      <Header />
      <Canvas>
        <Selection>
          <EffectComposer multisampling={8} autoClear={false}>
            <Outline
              blur
              visibleEdgeColor="yellow"
              edgeStrength={100}
              width={999}
            />
          </EffectComposer>
          <Suspense fallback={null}>
            <LowPolyIsland
              ref={islandRef}
              showAboutMe={showAboutMe}
              showContactMe={showContactMe}
              showEducation={showEducation}
              showWorkExperience={showWorkExperience}
              showSkills={showSkills}
              highlightEverything={selected}
              onSetCamera={setCameraHandler}
              lightHouseCameraPosition={lighthouseCameraPosition}
              toprightCloudCameraPosition={topRightCloudCameraPosition}
              fronttreeCameraPosition={frontTreeCameraPosition}
              topleftCloudCameraPosition={topLeftCloudCameraPosition}
              bottomrockCameraPosition={bottomRockCameraPosition}
              onSetScale={(sc) =>
                sc === 0 ? setSceneScale(homeScale) : setSceneScale(sc)
              }
              defaultCameraPosition={homeCameraPosition}
              ObjectScale={sceneScale}
              ObjectRotation={sceneRotation}
            />
            <OrbitControls />
            <Environment preset="sunset" />
          </Suspense>
        </Selection>
      </Canvas>
      <Toggle
        label="Show Available Objects"
        style={{
          zIndex: "1",
          bottom: "10px",
          right: "10px",
          position: "absolute",
        }}
        onClick={() => setToggle(!selected)}
        isToggleChecked={selected}
        onChange={() => {}}
      />
      <input
        type="image"
        src="resetButton.png"
        alt="reset camera"
        style={{
          position: "absolute",
          zIndex: "1",
          bottom: 10,
          left: 10,
          width: "60px",
        }}
        onClick={() => {
          islandRef.current.cameraResetClickHandler();
          setSceneRotation(homeRotation);
          setSceneScale(homeScale);
          setAboutMeDelayMSeconds(defaultDelayMS);
          setWorkExperienceDelayMSeconds(defaultDelayMS);
          setEducationDelayMSeconds(defaultDelayMS);
          setContactMeDelayMSeconds(defaultDelayMS);
          setSkillsDelayMSeconds(defaultDelayMS);
        }}
      />
    </div>
  );
}

export default App;
