import { useState, useRef } from "react";
import Modal from "./UI/Modal";
import "./ContactMe.css";
import { useFormspark } from "@formspark/use-formspark";
import ReCAPTCHA from "react-google-recaptcha";

const FORMSPARK_FORM_ID = "kWvmd9MW";
const FORMSPARK_ACTION_URL = "https://submit-form.com/kWvmd9MW";

function ContactMe(props) {
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });
  const recaptchaKey = "6Le19mUkAAAAAHe0l29UB4ZLNh99W9sy0GfV7GY0";
  const recaptchaRef = useRef();

  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [isFormValid, setisFormValid] = useState(true);
  const [formSparkError, setFormSparkError] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      setValidationMessage("Name is required.");
      setisFormValid(false);
      return;
    }
    if (email.trim() === "" || !email.trim().includes("@")) {
      setValidationMessage("Email is required.");
      setisFormValid(false);
      return;
    }
    if (message.trim() === "") {
      setValidationMessage("Message is required.");
      setisFormValid(false);
      return;
    }

    setisFormValid(true);
    setValidationMessage("");

    try {
      await fetch(FORMSPARK_ACTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          nameInput: name,
          emailInput: email,
          messageInput: message,
          "g-recaptcha-response": recaptchaToken,
        }),
      });
      setFormSubmitted(true);
    } catch (error) {
      setFormSparkError(true);
    }
  };

  const updateRecaptchaToken = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <Modal onCloseClick={props.closeContactMe} width={40} height={55}>
      <h2 className="contactHeader">Contact Me!</h2>
      <div className="overallContactMe">
        <div>
          <p
            style={{
              fontStyle: "italic",
              display: "inline-block",
            }}
          >
            Feel free to fill out this form, or message me on Linkedin.
          </p>
          <a
            href="https://www.linkedin.com/in/brady-shorts-4993a3228/"
            target="_blank"
            rel="noreferrer"
            style={{ display: "inline-block", marginLeft: "5px" }}
          >
            <img
              src="Linkedin-logo-icon-png.png"
              height="30px"
              alt="Linkedin logo"
            />
          </a>
        </div>
        <form onSubmit={onSubmit}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name"
            required=""
            onChange={(e) => setName(e.target.value)}
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Email"
            required=""
            onChange={(e) => setEmail(e.target.value)}
          />
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            placeholder="Message"
            required=""
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaKey}
            onChange={updateRecaptchaToken}
          />
          {!formSubmitted && !formSparkError && (
            <button type="submit" className="btn" disabled={submitting}>
              Send
            </button>
          )}
          {formSubmitted && (
            <h2 className="contactHeader">Thank you for contacting me!</h2>
          )}
          {formSparkError && (
            <h3 className="contactHeader">
              Sorry, there was an unforseen error, please contact me through
              Linkedin.
            </h3>
          )}
          {!isFormValid && (
            <p style={{ color: "red", fontWeight: "bold" }}>
              {validationMessage}
            </p>
          )}
        </form>
      </div>
    </Modal>
  );
}

export default ContactMe;
