import "./Header.css";

function Header(props) {
  return (
    <div className="header">
      <h3 className="hi">Hi, my name is</h3>
      <img
        src="brady_shorts.png"
        alt="Brady Shorts"
        className="brady"
        onClick={props.onNameClick}
      />
      <h3 className="welcome">welcome to my website.</h3>
    </div>
  );
}

export default Header;
